@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100.900;1,100.900&display=swap");

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    position: relative;
    font-family: 'GeneralSans-Regular', sans-serif; 
}

body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./modules/common/assets/image/noise.png') repeat; 
    opacity: 1; 
    pointer-events: none;
    z-index: 10; 
}


@font-face {
	font-family: "GeneralSans-Extralight";
	src:
		url("./fonts/GeneralSans-Extralight.woff2") format("woff2"),
		url("./fonts/GeneralSans-Extralight.woff") format("woff"),
		url("./fonts/GeneralSans-Extralight.ttf") format("truetype");
	font-weight: 200;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: "GeneralSans-ExtralightItalic";
	src:
		url("./fonts/GeneralSans-ExtralightItalic.woff2") format("woff2"),
		url("./fonts/GeneralSans-ExtralightItalic.woff") format("woff"),
		url("./fonts/GeneralSans-ExtralightItalic.ttf") format("truetype");
	font-weight: 200;
	font-display: swap;
	font-style: italic;
}
@font-face {
	font-family: "GeneralSans-Light";
	src:
		url("./fonts/GeneralSans-Light.woff2") format("woff2"),
		url("./fonts/GeneralSans-Light.woff") format("woff"),
		url("./fonts/GeneralSans-Light.ttf") format("truetype");
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: "GeneralSans-LightItalic";
	src:
		url("./fonts/GeneralSans-LightItalic.woff2") format("woff2"),
		url("./fonts/GeneralSans-LightItalic.woff") format("woff"),
		url("./fonts/GeneralSans-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-display: swap;
	font-style: italic;
}
@font-face {
	font-family: "GeneralSans-Regular";
	src:
		url("./fonts/GeneralSans-Regular.woff2") format("woff2"),
		url("./fonts/GeneralSans-Regular.woff") format("woff"),
		url("./fonts/GeneralSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: "GeneralSans-Italic";
	src:
		url("./fonts/GeneralSans-Italic.woff2") format("woff2"),
		url("./fonts/GeneralSans-Italic.woff") format("woff"),
		url("./fonts/GeneralSans-Italic.ttf") format("truetype");
	font-weight: 400;
	font-display: swap;
	font-style: italic;
}
@font-face {
	font-family: "GeneralSans-Medium";
	src:
		url("./fonts/GeneralSans-Medium.woff2") format("woff2"),
		url("./fonts/GeneralSans-Medium.woff") format("woff"),
		url("./fonts/GeneralSans-Medium.ttf") format("truetype");
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: "GeneralSans-MediumItalic";
	src:
		url("./fonts/GeneralSans-MediumItalic.woff2") format("woff2"),
		url("./fonts/GeneralSans-MediumItalic.woff") format("woff"),
		url("./fonts/GeneralSans-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-display: swap;
	font-style: italic;
}
@font-face {
	font-family: "GeneralSans-Semibold";
	src:
		url("./fonts/GeneralSans-Semibold.woff2") format("woff2"),
		url("./fonts/GeneralSans-Semibold.woff") format("woff"),
		url("./fonts/GeneralSans-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: "GeneralSans-SemiboldItalic";
	src:
		url("./fonts/GeneralSans-SemiboldItalic.woff2") format("woff2"),
		url("./fonts/GeneralSans-SemiboldItalic.woff") format("woff"),
		url("./fonts/GeneralSans-SemiboldItalic.ttf") format("truetype");
	font-weight: 600;
	font-display: swap;
	font-style: italic;
}
@font-face {
	font-family: "GeneralSans-Bold";
	src:
		url("./fonts/GeneralSans-Bold.woff2") format("woff2"),
		url("./fonts/GeneralSans-Bold.woff") format("woff"),
		url("./fonts/GeneralSans-Bold.ttf") format("truetype");
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: "GeneralSans-BoldItalic";
	src:
		url("./fonts/GeneralSans-BoldItalic.woff2") format("woff2"),
		url("./fonts/GeneralSans-BoldItalic.woff") format("woff"),
		url("./fonts/GeneralSans-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-display: swap;
	font-style: italic;
}
/**
  * This is a variable font
  * You can control variable axes as shown below:
  * font-variation-settings: wght 700.0;
  *
  * available axes:
  'wght' (range from 200.0 to 700.0
  */
@font-face {
	font-family: "GeneralSans-Variable";
	src:
		url("./fonts/GeneralSans-Variable.woff2") format("woff2"),
		url("./fonts/GeneralSans-Variable.woff") format("woff"),
		url("./fonts/GeneralSans-Variable.ttf") format("truetype");
	font-weight: 200 700;
	font-display: swap;
	font-style: normal;
}
/**
  * This is a variable font
  * You can control variable axes as shown below:
  * font-variation-settings: wght 700.0;
  *
  * available axes:
  'wght' (range from 200.0 to 700.0
  */
@font-face {
	font-family: "GeneralSans-VariableItalic";
	src:
		url("./fonts/GeneralSans-VariableItalic.woff2") format("woff2"),
		url("./fonts/GeneralSans-VariableItalic.woff") format("woff"),
		url("./fonts/GeneralSans-VariableItalic.ttf") format("truetype");
	font-weight: 200 700;
	font-display: swap;
	font-style: italic;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
.mini-scrollbar::-webkit-scrollbar-thumb {
	width: 2px;
	display: none;
}
